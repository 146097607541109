// Generated by Framer (28b2919)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Header from "https://framerusercontent.com/modules/Aa7nNgyGUaVMrvCGeoez/LU0ef61yo2J7JPojlbwk/cZUZ94QZI.js";
const HeaderFonts = getFonts(Header);

const cycleOrder = ["My0PV31Sp"];

const variantClassNames = {My0PV31Sp: "framer-v-1ey2kyh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "My0PV31Sp", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-LVFEd", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1ey2kyh", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"My0PV31Sp"} ref={ref} style={{...style}}><motion.div className={"framer-3j93b8-container"} data-framer-name={"nav with theme switcher"} layoutDependency={layoutDependency} layoutId={"pWSRaz2Op-container"} name={"nav with theme switcher"} transformTemplate={transformTemplate}><Header height={"100%"} id={"pWSRaz2Op"} layoutId={"pWSRaz2Op"} name={"nav with theme switcher"} style={{width: "100%"}} variant={"YqSNBPW7L"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-LVFEd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LVFEd .framer-grrrrh { display: block; }", ".framer-LVFEd .framer-1ey2kyh { height: 80px; overflow: hidden; position: relative; width: 200px; }", ".framer-LVFEd .framer-3j93b8-container { flex: none; height: auto; left: 0px; position: absolute; top: 0px; width: 1200px; z-index: 2; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerRDpVxfaTe: React.ComponentType<Props> = withCSS(Component, css, "framer-LVFEd") as typeof Component;
export default FramerRDpVxfaTe;

FramerRDpVxfaTe.displayName = "nav with theme switcher";

FramerRDpVxfaTe.defaultProps = {height: 80, width: 200};

addFonts(FramerRDpVxfaTe, [...HeaderFonts])